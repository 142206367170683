import { graphql, useStaticQuery } from "gatsby";
import { FounderHeroQuery } from "./query.generated";

export type CMSHeroData = FounderHeroQuery["cms"]["site"];

export function useStaticHeroQuery(): CMSHeroData {
  const data = useStaticQuery<FounderHeroQuery>(
    graphql`
      query FounderHero {
        cms {
          site(hostname: "www.italymondo.com") {
            founderPage: pages(
              contentType: "italymondo_about.IMFounderStoryPage"
            ) {
              ... on CMS_IMFounderStoryPage {
                __typename
                alertBar {
                  anchorText
                }
                heroDescription
                heroHeadline
                heroImage {
                  alt
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                heroPrimaryButton {
                  page {
                    url
                  }
                  title
                }
                heroSecondaryButton {
                  page {
                    url
                  }
                  title
                }
                heroType
                heroVerticalText {
                  name
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
