import React from "react";
import { bookACallIFrame } from "@italymondo/core-ui";
import { useStaticTransitionalCTAQuery } from "./query";
import { StyledTransitionalCTA } from "./styles";

export function TransitionalCTA(): React.ReactElement | null {
  const data = useStaticTransitionalCTAQuery();

  if (!data?.founderPage[0].bodyTransitionalCta) {
    return null;
  }

  return (
    <StyledTransitionalCTA
      action={data.founderPage[0].bodyTransitionalCta?.action[0]}
      buttonText={data.founderPage[0].bodyTransitionalCta?.buttonText}
      description={data.founderPage[0].bodyTransitionalCta?.description || ""}
      popupContent={bookACallIFrame}
      shadowImage={data.founderPage[0].bodyTransitionalCta?.shadowImage?.url}
      title={data.founderPage[0].bodyTransitionalCta?.title || ""}
    />
  );
}
