import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { SectionHeading } from "../containers";

export const Heading = styled(SectionHeading)`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.respTextAlign};
  `}
`;

export const CardsContainer = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    ${theme.breakpoints.down("sm")} {
      margin-top: ${theme.spacing(0)};
    }
  `}
`;

export const Card = styled("div")`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.rowCentered};
    background-color: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[1]};
    border-radius: ${theme.shape.siteBorderRadius.lg};
    padding: ${theme.spacing(2, 3)};
    height: 110px;
    width: 175px;
    > img {
      max-height: 90px;
      max-width: 150px;
    }
    ${theme.breakpoints.up("xl")} {
      width: 205px;
      > img {
        max-width: 190px;
      }
    }
  `}
`;
