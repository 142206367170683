import { graphql, useStaticQuery } from "gatsby";
import { FounderOurCompanyQuery } from "./query.generated";

export type CMSOurCompanyData = FounderOurCompanyQuery["cms"]["site"];

export function useStaticCompanyQuery(): CMSOurCompanyData {
  const data = useStaticQuery<FounderOurCompanyQuery>(
    graphql`
      query FounderOurCompany {
        cms {
          site(hostname: "www.italymondo.com") {
            founderPage: pages(
              contentType: "italymondo_about.IMFounderStoryPage"
            ) {
              ... on CMS_IMFounderStoryPage {
                aboutDescription
                aboutLinkLabel
                aboutMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 900)
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                aboutMediaPlacement
                aboutCta {
                  title
                  page {
                    url
                  }
                }
                aboutSectionName
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
