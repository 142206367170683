import React from "react";
import { StreamfieldBody } from "../../../components";
import { useStaticBodyQuery } from "./query";

export function Body(): React.ReactElement | null {
  const data = useStaticBodyQuery();

  if (!data?.founderPage[0]) {
    return null;
  }

  return <StreamfieldBody blocks={data?.founderPage[0].body} />;
}
