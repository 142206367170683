import { graphql, useStaticQuery } from "gatsby";
import { FounderSubbrandsQuery } from "./query.generated";

export type CMSSubbrandsData = FounderSubbrandsQuery["cms"]["site"];

export function useStaticSubbrandsQuery(): CMSSubbrandsData {
  const data = useStaticQuery<FounderSubbrandsQuery>(
    graphql`
      query FounderSubbrands {
        cms {
          site(hostname: "www.italymondo.com") {
            founderPage: pages(
              contentType: "italymondo_about.IMFounderStoryPage"
            ) {
              ... on CMS_IMFounderStoryPage {
                subBrands {
                  brand {
                    logo {
                      title
                      url
                    }
                    page {
                      url
                    }
                  }
                }
                subBrandsDescription
                subBrandsSectionName
                subBrandsTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
