import React from "react";
import {
  getPathname,
  Subbrands as SubbrandsComponent,
} from "@italymondo/core-ui";
import { useStaticSubbrandsQuery } from "./query";

export function Subbrands(): React.ReactElement | null {
  const data = useStaticSubbrandsQuery();

  if (!data?.founderPage[0]) {
    return null;
  }

  return (
    <SubbrandsComponent
      description={data?.founderPage[0].subBrandsDescription || ""}
      sectionName={data?.founderPage[0].subBrandsSectionName || ""}
      subbrands={
        data?.founderPage[0].subBrands.map((s) => ({
          alt: s.brand.logo.title || "",
          src: s.brand.logo.url || "",
          url: getPathname(s.brand.page?.url),
        })) || []
      }
      title={data?.founderPage[0].subBrandsTitle || ""}
    />
  );
}
