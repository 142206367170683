import React from "react";
import { Button, Founder, getPathname, Link } from "@italymondo/core-ui";
import { useStaticCompanyQuery } from "./query";

export function OurCompany(): React.ReactElement | null {
  const data = useStaticCompanyQuery();

  if (!data?.founderPage[0]) {
    return null;
  }

  function ctaLink(url: string): React.ReactElement {
    return (
      <Link to={url}>
        <Button color="primary" variant="contained">
          {data?.founderPage[0]?.aboutCta?.title}
        </Button>
      </Link>
    );
  }

  return (
    <Founder
      actions={ctaLink(getPathname(data?.founderPage[0].aboutCta?.page.url))}
      description={data?.founderPage[0].aboutDescription || ""}
      media={data?.founderPage[0].aboutMedia[0]}
      mediaPlacement={data?.founderPage[0].aboutMediaPlacement}
      sectionName={data?.founderPage[0].aboutSectionName || ""}
      // titleImage={data?.founderPage[0].aboutLogo.url}
      title="italyMONDO!"
    />
  );
}
