/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { useRef } from "react";
import { Grid } from "@mui/material";
import { SectionContainer } from "../containers";
import { useHasBeenAlmostVisible } from "../hooks";
import { Card, CardsContainer, Heading } from "./styles";

type PpSubbrands = {
  description: string;
  sectionName?: string;
  subbrands: Array<{
    alt: string;
    src: string;
    url?: string;
  }>;
  title: string;
};

export function Subbrands({
  description,
  sectionName,
  subbrands,
  title,
}: PpSubbrands): React.ReactElement {
  const elementRef = useRef<HTMLDivElement>(null);
  const shouldRenderContent = useHasBeenAlmostVisible(elementRef);

  return (
    <SectionContainer>
      <Grid
        ref={elementRef}
        container
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
        spacing={3}
      >
        <Grid item md={5}>
          <Heading
            alignItems={{ xs: "center", md: "flex-start" }}
            description={description}
            sectionName={sectionName}
            standardWidth={false}
            title={title}
          />
        </Grid>
        <Grid item md={7}>
          <CardsContainer
            container
            alignItems="center"
            direction={{ xs: "column", md: "row" }}
            justifyContent="flex-end"
            spacing={{ xs: 4, md: 3 }}
          >
            {subbrands.map((s) => (
              <Grid key={s.alt} item>
                <Card>
                  {shouldRenderContent ? (
                    <img alt={s.alt} src={s.src} />
                  ) : (
                    <div />
                  )}
                </Card>
              </Grid>
            ))}
          </CardsContainer>
        </Grid>
      </Grid>
    </SectionContainer>
  );
}
