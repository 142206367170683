import React from "react";
import loadable from "@loadable/component";
import { PpMediaImagesBlock } from "./MediaImagesBlock";
import { PpTestimonialsBlock } from "./TestimonialsBlock";

const MediaImagesBlock = loadable(() => import("./MediaImagesBlock"), {
  resolveComponent: (components) => components.MediaImagesBlock,
});
const TestimonialsBlock = loadable(() => import("./TestimonialsBlock"), {
  resolveComponent: (components) => components.TestimonialsBlock,
});

export enum MediaPlacementType {
  BOTTOM = "BOTTOM",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  TOP = "TOP",
}

export type PpMedia = {
  media: {
    __typename: string;
    images?: PpMediaImagesBlock["images"];
    testimonials?: PpTestimonialsBlock["testimonials"];
  };
};

export function Media({ media, ...rest }: PpMedia): React.ReactElement | null {
  if (
    media.__typename === "CMS_TestimonialsBlock" &&
    media.testimonials &&
    media.testimonials.length
  ) {
    return <TestimonialsBlock testimonials={media.testimonials} {...rest} />;
  }
  if (
    media.__typename === "CMS_MediaImagesBlock" &&
    media.images &&
    media.images.length
  ) {
    return <MediaImagesBlock images={media.images} {...rest} />;
  }
  return null;
}
