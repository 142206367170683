import { graphql, useStaticQuery } from "gatsby";
import { FounderContactFormQuery } from "./query.generated";

export type CMSContactFormData = FounderContactFormQuery["cms"];

export function useStaticContactFormQuery(): CMSContactFormData {
  const data = useStaticQuery<FounderContactFormQuery>(
    graphql`
      query FounderContactForm {
        cms {
          site(hostname: "www.italymondo.com") {
            founderPage: pages(
              contentType: "italymondo_about.IMFounderStoryPage"
            ) {
              ... on CMS_IMFounderStoryPage {
                contactFormSectionName
                footerContactForm {
                  confirmationPage {
                    url
                  }
                  description
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
