import { graphql, useStaticQuery } from "gatsby";
import { FounderTransitionalCTAQuery } from "./query.generated";

export type CMSTransitionalCTAData = FounderTransitionalCTAQuery["cms"]["site"];

export function useStaticTransitionalCTAQuery(): CMSTransitionalCTAData {
  const data = useStaticQuery<FounderTransitionalCTAQuery>(
    graphql`
      query FounderTransitionalCTA {
        cms {
          site(hostname: "www.italymondo.com") {
            founderPage: pages(
              contentType: "italymondo_about.IMFounderStoryPage"
            ) {
              ... on CMS_IMFounderStoryPage {
                bodyTransitionalCta {
                  action {
                    ... on CMS_CollectEmailBlock {
                      __typename
                      collectEmail
                    }
                    ... on CMS_LinkPageBlock {
                      __typename
                      page {
                        url
                      }
                    }
                    ... on CMS_ShowPopupBlock {
                      __typename
                      description
                      title
                    }
                  }
                  buttonText
                  description
                  shadowImage {
                    url
                  }
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
