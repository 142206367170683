import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO } from "../../components";
import {
  Body,
  ContactForm,
  Hero,
  OurCompany,
  Subbrands,
  TransitionalCTA,
} from "../../sections/Founder";
import { FounderQuery } from "./index.generated";

type PpFounderPage = {
  data: FounderQuery;
  location?: {
    pathname?: string;
  };
};

export default function FounderPage({
  data: {
    cms: { site },
  },
  location,
}: PpFounderPage): React.ReactElement {
  const alertBarData = site.founderPage[0].alertBar?.message
    ? {
        link: {
          text: site.founderPage[0].alertBar?.anchorText || "",
          url: getPathname(site.founderPage[0].alertBar?.page.url) || "",
        },
        text: site.founderPage[0].alertBar?.message || "",
      }
    : null;

  return (
    <Layout
      alertBar={alertBarData}
      showProgressBar={site.founderPage[0].progressBar}
    >
      <SEO
        description={site.founderPage[0]?.listingSummary || ""}
        image={site.founderPage[0]?.listingImage?.url}
        location={location}
        title={site.founderPage[0]?.listingTitle || ""}
      />
      <Hero />
      <Body />
      <TransitionalCTA />
      <OurCompany />
      <Subbrands />
      <ContactForm />
    </Layout>
  );
}

export const query = graphql`
  query Founder {
    cms {
      site(hostname: "www.italymondo.com") {
        founderPage: pages(contentType: "italymondo_about.IMFounderStoryPage") {
          ... on CMS_IMFounderStoryPage {
            alertBar {
              anchorText
              message
              page {
                url
              }
            }
            heroImage {
              url
            }
            listingImage {
              url
            }
            listingSummary
            listingTitle
            progressBar
          }
        }
      }
    }
  }
`;
