import { graphql, useStaticQuery } from "gatsby";
import { FounderBodyQuery } from "./query.generated";

export type CMSBodyData = FounderBodyQuery["cms"]["site"];

export function useStaticBodyQuery(): CMSBodyData {
  const data = useStaticQuery<FounderBodyQuery>(
    graphql`
      query FounderBody {
        cms {
          site(hostname: "www.italymondo.com") {
            founderPage: pages(
              contentType: "italymondo_about.IMFounderStoryPage"
            ) {
              ... on CMS_IMFounderStoryPage {
                body {
                  ... on CMS_RichTextBlock {
                    __typename
                    value
                  }
                  ... on CMS_ImageBlock {
                    __typename
                    image {
                      alt
                      url
                    }
                  }
                  ... on CMS_GalleryImageBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              width: 950
                              height: 650
                            )
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_ShadowHeaderParagraphBlock {
                    __typename
                    paragraph
                    shadowHeader
                  }
                  ... on CMS_SignatureBlock {
                    __typename
                    signature {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
